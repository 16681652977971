import React from "react";
import s from "./AwardsCouncilDeputies.module.css";
import gerb from "../../assets/img/khimkiDistrict/Symbolism/gerb.png";

const AwardsCouncilDeputies = () => {
  return (
    <div>
      <div className="text">
        <div className={s.logoBlock}>
          <div className={s.imgContainer}>
            <img src={gerb} alt="" />
          </div>
          <div className={s.textBlock}>
            Утверждено <br />
            Решением Совета депутатов <br />
            городского округа от 30.05.2012 № 7/9
          </div>
        </div>

        <div className={`pageTitle mt40 ${s.mobilMt20}`}>
          Положение о почетной грамоте Совета депутатов городского округа Химки
        </div>

        <div className="mt40">
          <p>I. Общие положения</p>
          <br />
          <ol>
            <li>
              Почетная грамота Совета депутатов городского округа Химки является
              формой поощрения граждан, предприятий, организаций и учреждений за
              заслуги перед городским округом Химки.
            </li>
            <li>
              Основаниями для награждения Почетной грамотой Совета депутатов
              городского округа Химки (далее - Почетная грамота) являются:
            </li>
          </ol>
          <ul>
            <li>
              достижения в области экономики, науки, культуры, спорта,
              воспитания и охраны здоровья;
            </li>
            <li>достижение высоких результатов в трудовой деятельности;</li>
            <li>
              осуществление мер по обеспечению и защите прав и свобод человека и
              гражданина, общественного порядка;
            </li>
            <li>вклад в развитие нормотворческой деятельности;</li>
            <li>активное участие в благотворительной деятельности;</li>
            <li>активное участие в общественной жизни;</li>
            <li>
              юбилейные даты для трудовых коллективов и организаций (10 лет,
              далее каждые последующие 5 лет с момента образования);
            </li>
            <li>
              юбилейные даты для граждан (по достижению 50 лет и каждые
              последующие 5 лет).
            </li>
          </ul>
          <br />
          <div>
            II. Порядок награждения Почетной грамотой Совета депутатов
            городского округа Химки
          </div>
          <br />
          <ol start={3}>
            <li>
              Награждение Почетной грамотой от имени Совета депутатов городского
              округа Химки производится Председателем Совета депутатов на
              основании ходатайств, представляемых:
            </li>
          </ol>
          <ul>
            <li>депутатами Совета депутатов городского округа Химки;</li>
            <li>Главой городского округа Химки;</li>
            <li>
              предприятиями, организация и учреждениями, расположенными на
              территории городского округа Химки;
            </li>
            <li>общественными объединениями.</li>
          </ul>
          <ol start={4}>
            <li>
              Ходатайство о награждении Почетной грамотой должно содержать
              обоснование представления к награждению. К ходатайству
              прилагаются:
            </li>
          </ol>
          <ul>
            <li>
              Для трудовых коллективов и организаций - сведения о
              социально-экономических, научных и иных достижениях; при
              награждении в год юбилейной даты - архивная справка о дате
              образования организации.
            </li>
            <li>
              Для граждан - характеристика представляемого гражданина с
              указанием следующих сведений: фамилия, имя, отчество (полностью);
              дата рождения (число, месяц, год); место работы, службы, профессия
              и должность; ученая степень, звание; общий стаж работы, стаж
              работы в данной организации (как правило, не менее 3 лет);
              образование, сведений о трудовой деятельности и конкретных заслуг.
            </li>
          </ul>
          <ol start={5}>
            <li>
              Материалы по награждению представляются в Совет депутатов
              городского округа Химки не позднее чем за 15 дней до рассмотрения
              вопроса о награждении.{" "}
            </li>
            <li>
              В награждении Почетной грамотой может быть отказано в случаях:
            </li>
          </ol>
          <ul>
            <li>
              Непредставления документов, указанных в пункте 2 настоящего
              Положения.
            </li>
            <li>
              Несоответствия основаниям для награждения, указанным в пункте 2
              настоящего Положения.
            </li>
          </ul>
          <ol start={7}>
            <li>
              Почетная грамота подписывается Председателем Совета депутатов
              городского округа Химки и скрепляется гербовой печатью.
            </li>
            <li>
              Вручение Почетной грамоты производится Председателем Совета
              депутатов по его поручению заместителем Председателя, либо
              депутатами Совета депутатов в Совете депутатов, на торжественных
              мероприятиях, или непосредственно в трудовых коллективах и
              организациях.
            </li>
            <li>
              Учет и регистрацию граждан, предприятий, учреждений и организаций,
              награжденных Почетной грамотой, осуществляет Аппарат Совета
              депутатов.
            </li>
            <li>
              Информация о награждении Почетной грамотой публикуется в средствах
              массовой информации.
            </li>
            <li>
              Лица, награжденные Почетной грамотой, могут представляться к
              награждению Почетной грамотой Совета депутатов не раньше чем через
              год после предыдущего награждения.
            </li>
            <li>При утрате Почетной грамоты дубликат не выдается.</li>
            <li>
              Сведения о награждении Почетной грамотой вносятся в личное дело и
              трудовую книжку поощренных лиц отделом кадров организации, в
              которой работает гражданин.
            </li>
            <li>
              Лишение Почетной грамоты может быть произведено по решению Совета
              депутатов в случае совершения умышленного преступления.
            </li>
          </ol>
        </div>

        <div className={`${s.logoBlock} mt80`}>
          <div className={s.imgContainer}>
            <img src={gerb} alt="" />
          </div>
          <div className={s.textBlock}>
            Утверждено <br />
            Решением Совета депутатов <br />
            городского округа от 30.05.2012 № 7/10
          </div>
        </div>

        <div className={`pageTitle mt40 ${s.mobilMt20}`}>
          Положение о благодарственном письме совета депутатов городского округа
          химки
        </div>

        <div className="mt40">
          <p>I. Общие положения</p>
          <br />
          <ol>
            <li>
              Благодарственное письмо Совета депутатов городского округа Химки
              является формой поощрения граждан, предприятий, организаций и
              учреждений за заслуги перед городским округом Химки.
            </li>
            <li>
              Основаниями для награждения Благодарственным письмом Совета
              депутатов городского округа Химки (далее - Благодарственное
              письмо) являются:
            </li>
          </ol>
          <ul>
            <li>значительные трудовые, производственные достижения;</li>
            <li>
              значительный вклад в реализацию государственных и муниципальных
              программ социально-экономического развития городского округа
              Химки;
            </li>
            <li>
              значительный вклад в реализацию государственных и муниципальных
              программ социально-экономического развития городского округа
              Химки;
            </li>
            <li>
              внесение пожертвований на социальную поддержку малообеспеченных
              граждан, строительство и реконструкцию школ, поликлиник,
              культурных центров и других социально важных объектов и памятников
              культуры городского округа Химки;
            </li>
            <li>профессиональные праздники;</li>
            <li>содействие деятельности правоохранительных органов;</li>
            <li>
              занятие призовых мест в проводимых конкурсах, соревнованиях.
            </li>
          </ul>
          <br />
          <div>
            II. Порядок награждения Благодарственным письмом Совета депутатов
            городского округа Химки
          </div>
          <br />
          <ol start={3}>
            <li>
              Награждение Благодарственным письмом от имени Совета депутатов
              городского округа Химки производится Председателем Совета
              депутатов на основании ходатайств, представляемых:
            </li>
          </ol>
          <ul>
            <li>депутатами Совета депутатов городского округа Химки;</li>
            <li>Главой городского округа Химки;</li>
            <li>
              предприятиями, организация и учреждениями, расположенными на
              территории городского округа Химки;
            </li>
            <li>общественными объединениями.</li>
          </ul>
          <ol start={4}>
            <li>
              Ходатайство о награждении Благодарственным письмом должно
              содержать обоснование представления к награждению.
            </li>
            <li>
              Материалы по награждению представляются в Совет депутатов
              городского округа Химки не позднее чем за 15 дней до рассмотрения
              вопроса о награждении.{" "}
            </li>
            <li>
              В награждении Благодарственным письмом может быть отказано в
              случае несоответствия основаниям для награждения, указанным в
              пункте 2 настоящего Положения.
            </li>
            <li>
              Благодарственное письмо подписывается Председателем Совета
              депутатов городского округа Химки и скрепляется гербовой печатью.
            </li>
            <li>
              Вручение Благодарственного письма производится Председателем
              Совета депутатов, либо по его поручению заместителем Председателя,
              либо депутатами Совета депутатов в Совете депутатов, на
              торжественных мероприятиях, или непосредственно в трудовых
              коллективах и организациях.
            </li>
            <li>
              Учет и регистрацию граждан, предприятий, учреждений и организаций,
              награжденных Благодарственным письмом, осуществляет Аппарат Совета
              депутатов.
            </li>
            <li>При утрате Благодарственного письма дубликат не выдается.</li>
          </ol>
        </div>
      </div>
    </div>
  );
};
export default AwardsCouncilDeputies;
